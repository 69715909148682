.list , .list1{

    padding: 15px ;
    display: flex;
    flex-direction: column;
    background-color:black  ;
    z-index: 10  ;
    width: 250px;
    position: absolute;
    list-style: none;
    height: auto;
    align-items: flex-start;
    
}
.item{
    text-decoration: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    font-family: "Nunito Sans";
    color: rgb(119,119,119);
}

.item:hover{
    text-decoration: none;
    color:whitesmoke
}


.img-grid{

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  margin :20px;
}
.img-wrap{
  overflow: hidden;
  height: 0;
  padding: 80% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
  border: 2px solid rgb(207,148,80);
}
.img-wrap img{
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.img-grid p{
    display: flex;
}

.img-wrap1{
  
  
  
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
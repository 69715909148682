
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body{
  background-color: #121722;
}

h1,h2,h3,h4, h5, h6,label,p{
  color: whitesmoke;
}
.NavbarItems2{
    background: rgb(18,23,34);
    height:120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    z-index: 1;
    top: 0;
    border-bottom: solid 1px var(--primary-color);
      opacity: 0.9;
    /* overflow: hidden; */
    width: 100%;
    padding: 0.7rem 2rem;
    
}

.navbar-logo2{
    color: whitesmoke;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;

}

.f{
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu2{
    display: grid;
    grid-template-columns: repeat(6,auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
    text-decoration: none;

}

.nav-menu2.active{
    color:blue
}
.nav-links2{
    color: rgb(184,186,189);
    text-decoration: none;
    padding: 0.5rem 1rem;
   font-family: "Nunito Sans";
   font-size: 12px;
   font-weight: 600;
   letter-spacing: 1px;
   line-height: 100px;
   font-style: normal;
}
.nav-links2:hover{
   
    border-radius: 4px;
    text-decoration:none ;
    transition: all 0.2s ease-out;
    color:white

}

.nav-links-portal2{
    color:white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-family:"Nunito Sans" ;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
   line-height: 100px;
   font-style: normal;
}


.nav-links2:active{
   
    border-radius: 4px;
    text-decoration:none ;
    transition: all 0.2s ease-out;
    color:rgb(207,148,80);
}


.nav-links-portal2:hover{
   
    border-radius: 4px;
    text-decoration:none ;
    transition: all 0.2s ease-out;
    color: white
}

.fa-bars{
    color:rgb(207,148,80);
}

.nav-links-mobile{
    display: none;
}
.nav-links2:clicked{
    background-color: grey;
}

.menu-icon{
    display: none;
}

@media screen and (max-width:960px) {
    .NavbarItems2{
        position: fixed;
    }    
    img{
        width: 80px;
        padding-top: 0px;
    }

    .nav-menu2{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5ms ease;
        z-index: 3;
    }
    .nav-links-portal2{
        color:black;
        text-decoration: none;
     
        font-family:"Nunito Sans" ;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
       line-height: 80px;
       font-style: normal;
    }
    .nav-links-portal2:hover{
        color: rgb(207,148,80);
        text-decoration: none;
     
        
    }
    .nav-menu2.active{
        background: whitesmoke;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        overflow-y: scroll;
    }
    .nav-menu2.active::-webkit-scrollbar{
        display: none;
    }

    .nav-links2{
        text-align: center;
        padding:0.1rem;
        width: 100%;
        color: black;
        
    }
    .nav-links2:hover{
        color: rgb(207,148,80);
       border-radius: 0;
    }

    
    .navbar-logo{
        position: fixed;
        top:0;
        left:0;
        transform: translate(25%, 50%);
    }


    .menu-icon{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
    .fa-times{
        color:rgb(207,148,80);;
        font-size: 2rem;
    }

    .nav-links-mobile{
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #4ad9e4;
        text-decoration: none;
        color:black;
        font-size: 1.5rem;
    }
    
}

